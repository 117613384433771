$( document ).ready(function() {

  $("form").each(function(){
    if($(this).hasClass("mc4wp-form"))
    {
      return;
    }
    $(this).append( '<input type="hidden" name="antispam" value="not_a_bot"></input>' );

  });

 
  setTimeout(function(){
    $('.sticky-message').addClass("show");
  }, 500);

  setTimeout(function(){
    $('.sticky-message').removeClass("show");
  }, 4000);

  $('.sticky-message').click(function(){
    $(this).removeClass("show");
  });


});
