import {$,jQuery} from 'jquery';
/*IMPORT SCSS*/
import '../styles/index.scss';

/*Kalkulator*/
import '../scripts/kalkulator.js';

/*Kontaktskjema*/
import '../scripts/kontaktskjema.js';

/*Diverse*/
import '../scripts/diverse.js';
