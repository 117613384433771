$( document ).ready(function() {

  $('.utleiekalkulator button').click(function() {
    var input = $('.utleiekalkulator input.kalkulatorinput').val();
    var output = (input / 100) * 90;
    var feedback = 'Du får utbetalt ' + thousands_separators(Math.round(output)) + ' kroner hver måned fra andre måned.';
    var error = 'Noe gikk galt, sjekk at du tastet en reell sum i feltet.';

    if($.isNumeric(input)){
      $('.kalkulator-resultater span.resultat').html(feedback);
      $('.kalkulator-addition-containers').removeClass("hide");
    }
    else{
      $('.kalkulator-resultater span.resultat').html(error);
    }
  });

  $( ".utleiekalkulator" ).each(function() {
    $('.kalkulator-addition-containers').addClass("hide");
  });


});

function thousands_separators(num)
{
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return num_parts.join(".");
}
