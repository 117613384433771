$( document ).ready(function() {

  $('.header .burger').click(function() {
    $(this).toggleClass('open');
    $('.header .menu').toggleClass('show');
    $('.header svg').toggleClass('dark');
    $('body').toggleClass('locked');
  });

  $('footer .scroll-to-top').click(function(){
    $('html,body').animate({ scrollTop: 0 }, 200, 'swing');
    return false;
  });

  $('.referanse-slider').slick({
    autoplay: true,
    dots: true,
  });

  $(document).scroll(function () {
    var $nav = $("nav.header.transparent");
    var $block = $('.header .video-container');
    $nav.toggleClass('scrolled', $(this).scrollTop() > $block.height());
  });


});
